import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";

import { translate } from "react-switch-lang";
import { withRouter, useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { getAllBranches } from "../../services/branch.service";
import { getAllRoles } from "../../services/role.service";
import Loader from "../partials/Loader";
import { getUserById, updateUser } from "../../services/users.srvices";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const EditUser = (props) => {
  const [rolesData, setRolesData] = useState([]);
  const [editData, setEditData] = useState([]);
  const { userId } = useParams();

  const [branchesData, setBranchesData] = useState([]);

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userRoleIdBeforeEdit, setUserRoleIdBeforeEdit] = useState([]);
  const [userBranchesBeforeEdit, setUserBranchesBeforeEdit] = useState([]);
  const [userDefaultBranchBeforeEdit, setUserDefaultBranchBeforeEdit] = useState([]);
  const [branchesIds, setBranchesIds] = useState();
  const [correctPhoneNumber, setCorrectPhoneNumber] = useState(true);
  const [togglePassword, setTogglePassword] = useState(false);

  const handlePhoneNumberCorrection = (pp) => {
    if (pp.slice(0, 2) === "05" && pp.length === 10)
      setCorrectPhoneNumber(true);
    else if (pp.length === 0) setCorrectPhoneNumber(true);
    else setCorrectPhoneNumber(false);
  };

  // UI/UX
  const [loadingBranchesData, setLoadingBranchesData] = useState(false);
  const [loadingRolesData, setLoadingRolesData] = useState(false);

  // Store
  const dispatch = useDispatch();

  // API Configuration

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(setGlobalLoading({ status: true }));
    let brIds = [];
    userBranchesBeforeEdit.forEach((br) => {
      brIds.push(br.id);
    });

    const apiData = {
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
      displayName: displayName,
      password: event.target.password.value,
      branchesIds: brIds,
      defaultBranchId: userDefaultBranchBeforeEdit[0]?.id,
      // need to change
      roleId: userRoleIdBeforeEdit[0].id,
      id: editData.id,
    };

    (async () => {
      const { ok } = await updateUser(apiData);
      if (ok) {
        dispatch(setGlobalLoading({ status: false }));
        toast.success(props.t("userUpdatedSuccessfuly"));

        props.history.push(`${process.env.PUBLIC_URL}/users`);
      }
    })();
  };
  const handleCancel = () => {
    props.history.push(`${process.env.PUBLIC_URL}/users`);
  };

  useEffect(() => {
    if (userId !== "" && userId) {
      (async () => {
        const { body, ok } = await getUserById(userId);
        if (ok) {
          setEditData(body);
          setPhoneNumber(body.phoneNumber);
          setBranchesIds(body.branchesIds);
          setRoleId(body.roleId);
          setUserName(body.userName);
          setDisplayName(body.displayName);
          setEmail(body.email);
        }
      })();
    }
  }, [userId]);

  useEffect(() => {
    setLoadingBranchesData(true);
    (async () => {
      const { body, ok } = await getAllBranches();
      if (ok) {
        setBranchesData(body);
        if (branchesIds?.length > 0) {
          branchesIds.forEach((id) => {
            body.forEach((branch) => {
              if (branch.id === id) {
                setUserBranchesBeforeEdit((oldArray) => [...oldArray, branch]);
              }
            });
          });
        }
        if (editData.defaultBranchId?.length > 0) {
          body.forEach((branch) => {
            if (branch.id === editData.defaultBranchId) {
              setUserDefaultBranchBeforeEdit((oldArray) => [...oldArray, branch]);
            }
          });
        }
      }
      setLoadingBranchesData(false);
    })();
  }, [branchesIds]);
  useEffect(() => {
    setLoadingRolesData(true);
    (async () => {
      const { body, ok } = await getAllRoles();
      if (ok) {
        setRolesData(body);
        body.forEach((role) => {
          if (role.id === roleId) {
            setUserRoleIdBeforeEdit((oldArray) => [...oldArray, role]);
          }
        });
      }
      setLoadingRolesData(false);
    })();
  }, [roleId]);

  const handleInputChange = (input, e) => {
    setUserBranchesBeforeEdit(input);
  };
  const handleDefaultBranchChange = (input) => {
    setUserDefaultBranchBeforeEdit(input);
  };
  const handleSelectRole = (value) => {
    // if (value.length > 0) {
    setUserRoleIdBeforeEdit(value);
    if (value.length > 0) {
      setRoleId(value[0].id);
    } else setRoleId("");
    console.log(value);
    // }
  };
  const isSaveButtonDisabled = () => {
    return (
      userName?.length > 0 &&
      email?.length > 0 &&
      displayName?.length > 0 &&
      roleId?.length > 0
      // userRoleIdBeforeEdit[0].id.length > 0
    );
  };
  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("users")}
        title={props.t("editUser")}
        backTo="true"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {loadingBranchesData || loadingRolesData ? (
                <Loader />
              ) : (
                <Form className="form theme-form" onSubmit={handleSubmit}>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("userName")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              type="text"
                              name="userName"
                              defaultValue={editData.userName}
                              onChange={(event) => {
                                setUserName(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("email")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              type="text"
                              name="email"
                              defaultValue={editData.email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("phoneNumber")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className={
                                correctPhoneNumber
                                  ? "form-control"
                                  : "form-control is-invalid border-danger"
                              }
                              type="number"
                              name="phoneNumber"
                              // max="10"
                              defaultValue={phoneNumber}
                              // max="999"
                              onChange={(event) => {
                                setPhoneNumber(
                                  event.target.value.replace(/\s+/g, "")
                                );
                                handlePhoneNumberCorrection(
                                  event.target.value.replace(/\s+/g, "")
                                );
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("displayName")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              type="text"
                              name="displayName"
                              defaultValue={editData.displayName}
                              onChange={(event) => {
                                setDisplayName(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("password")}
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              name="password"
                              defaultValue={editData.password}
                            />
                            <div
                              className="show-hide"
                              style={{ zIndex: 1, top: "16px" }}
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("role")}
                          </Label>
                          <Col sm="9">
                            <Typeahead
                              id="selectRole"
                              labelKey="name"
                              isLoading={loadingRolesData}
                              options={rolesData}
                              onChange={handleSelectRole}
                              placeholder={props.t("selectPlaceholder")}
                              clearButton={true}
                              selected={userRoleIdBeforeEdit}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("branch")}
                          </Label>
                          <Col sm="9">
                            <Typeahead
                              id="custom-typeahead"
                              labelKey="name"
                              multiple
                              sLoading={loadingBranchesData}
                              newSelectionPrefix="Add a new item: "
                              options={branchesData}
                              onChange={handleInputChange}
                              placeholder={props.t("selectPlaceholder")}
                              clearButton={true}
                              selected={userBranchesBeforeEdit}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            {props.t("defaultBranch")}
                          </Label>
                          <Col sm="9">
                            <Typeahead
                              id="custom-typeahead"
                              labelKey="name"
                              options={branchesData}
                              placeholder={props.t("selectPlaceholder")}
                              onChange={handleDefaultBranchChange}
                              isLoading={loadingBranchesData}
                              clearButton
                              selected={userDefaultBranchBeforeEdit}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="submit-footer">
                    <Button
                      color="primary"
                      className="mr-1"
                      type="submit"
                      disabled={!isSaveButtonDisabled() || !correctPhoneNumber}
                    >
                      {props.t("save")}
                    </Button>
                    <Button color="light" type="reset" onClick={handleCancel}>
                      {props.t("cancel")}
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(EditUser));
