import React, { Fragment, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { getAllBranches } from "../../services/branch.service";

import { getAllRoles } from "../../services/role.service";
import { addUser } from "../../services/users.srvices";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const AddUser = (props) => {
  const [rolesData, setRolesData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [roleId, setRoleId] = useState("");
  const [branchesIds, setBranchesIds] = useState([]);
  const [defaultBranchId, setDefaultBranchId] = useState([]);

  // Password Confirmation
  const [matching, setMatching] = useState(true);
  const [correctPhoneNumber, setCorrectPhoneNumber] = useState(true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);

  const handleNewMatching = (pp) => {
    if (confirmPassword === pp) setMatching(true);
    else setMatching(false);
  };
  const handleConfirmMatching = (pp) => {
    if (password === pp) setMatching(true);
    else setMatching(false);
  };
  const handlePhoneNumberCorrection = (pp) => {
    if (pp.slice(0, 2) === "05" && pp.length === 10)
      setCorrectPhoneNumber(true);
    else if (pp.length === 0) setCorrectPhoneNumber(true);
    else setCorrectPhoneNumber(false);
  };

  // UI/UX
  const [branchesLoading, setBranchesLoading] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(false);

  //Store
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));

    let branchesIdsToSubmit = [];
    if (branchesIds.length > 0) {
      branchesIdsToSubmit = branchesIds.map((branch) => branch.id);
    }

    const apiData = {
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
      displayName: displayName,
      password: password,
      branchesIds: branchesIdsToSubmit,
      defaultBranchId: defaultBranchId[0]?.id,
      roleId: roleId,
    };
    (async () => {
      const { ok } = await addUser(apiData);
      if (ok) {
        toast.success(props.t("userAddedSuccessfuly"));
        props.history.push(`${process.env.PUBLIC_URL}/users`);
      }
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  const handleCancel = (event) => {
    props.history.push(`${process.env.PUBLIC_URL}/users`);
  };

  useEffect(() => {
    (async () => {
      setRolesLoading(true);
      const { body, ok } = await getAllRoles();
      if (ok) {
        setRolesData(body);
      }

      setRolesLoading(false);
    })();
    (async () => {
      setBranchesLoading(true);
      const { body, ok } = await getAllBranches();
      if (ok) {
        setBranchesData(body);
      }

      setBranchesLoading(false);
    })();
  }, []);

  const handleInputChange = (input) => {
    setBranchesIds(input);
  };
  const handleDefaultBranchChange = (input) => {
    setDefaultBranchId(input);
  };

  const handleSelectRole = (value) => {
    if (value.length > 0) {
      setRoleId(value[0].id);
    }
  };
  const isSaveButtonDisabled = () => {
    return (
      userName.length > 0 &&
      email.length > 0 &&
      displayName.length > 0 &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      roleId.length > 0
    );
  };
  return (
    <Fragment>
      <Breadcrumb
        parent={props.t("users")}
        title={props.t("addUser")}
        backTo="true"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={handleSubmit}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("userName")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="userName"
                            onChange={(event) => {
                              setUserName(event.target.value);
                            }}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("email")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("phoneNumber")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className={
                              correctPhoneNumber
                                ? "form-control"
                                : "form-control is-invalid border-danger"
                            }
                            type="number"
                            name="phoneNumber"
                            // max="10"
                            defaultValue={phoneNumber}
                            onChange={(event) => {
                              setPhoneNumber(
                                event.target.value.replace(/\s+/g, "")
                              );
                              handlePhoneNumberCorrection(
                                event.target.value.replace(/\s+/g, "")
                              );
                            }}
                          />
                        </Col>
                      </FormGroup>
                      {/* <span className='text-danger col-sm-9'>{matching ? "" : props.t("notMatchMsg")}</span> */}

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("displayName")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type="text"
                            name="displayName"
                            onChange={(event) => {
                              setDisplayName(event.target.value);
                            }}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("password")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className="form-control"
                            type={togglePassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              handleNewMatching(e.target.value);
                            }}
                            defaultValue={password}
                            required
                          />
                          <div
                            className="show-hide"
                            style={{ zIndex: 1, top: "16px" }}
                            onClick={() => setTogglePassword(!togglePassword)}
                          >
                            <span
                              className={togglePassword ? "" : "show"}
                            ></span>
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("confirmPassword")}
                        </Label>
                        <Col sm="9">
                          <Input
                            className={
                              matching
                                ? "form-control"
                                : "form-control is-invalid border-danger"
                            }
                            type={toggleConfirmPassword ? "text" : "password"}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              handleConfirmMatching(e.target.value);
                            }}
                            defaultValue={confirmPassword}
                            required=""
                          />
                          <div
                            className="show-hide"
                            style={{ zIndex: 1, top: "16px" }}
                            onClick={() =>
                              setToggleConfirmPassword(!toggleConfirmPassword)
                            }
                          >
                            <span
                              className={toggleConfirmPassword ? "" : "show"}
                            ></span>
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("role")}
                        </Label>
                        <Col sm="9">
                          <Typeahead
                            id="selectRole"
                            labelKey="name"
                            options={rolesData}
                            placeholder={props.t("selectPlaceholder")}
                            onChange={handleSelectRole}
                            isLoading={rolesLoading}
                            clearButton
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("branch")}
                        </Label>
                        <Col sm="9">
                          <Typeahead
                            id="custom-typeahead"
                            labelKey="name"
                            multiple
                            options={branchesData}
                            placeholder={props.t("selectPlaceholder")}
                            onChange={handleInputChange}
                            isLoading={branchesLoading}
                            clearButton
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("defaultBranch")}
                        </Label>
                        <Col sm="9">
                          <Typeahead
                            id="custom-typeahead"
                            labelKey="name"
                            options={branchesData}
                            placeholder={props.t("selectPlaceholder")}
                            onChange={handleDefaultBranchChange}
                            isLoading={branchesLoading}
                            clearButton
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={
                      !isSaveButtonDisabled() ||
                      !correctPhoneNumber ||
                      !matching
                    }
                  >
                    {props.t("save")}
                  </Button>
                  <Button color="light" type="reset" onClick={handleCancel}>
                    {props.t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(AddUser));
