import environment from "../environment";
import { Get, Post } from "./http.service";

const _endpoint = environment.USER_ENDPOINT;


export const getAllUsers = async () => {
  const response = await Get(`${_endpoint}/GetAll`, true);
  return response;
};
export const search = async (data) => {
  const response = await Post(`${_endpoint}/Search`, data, true);
  return response;
};
export const getUserById = async (userId) => {
  const response = await Get(`${_endpoint}/Get?id=${userId}`, true);
  return response;
};

export const getProfileInfo = async () => {

  const response = await Get(`${_endpoint}/GetProfile`, localStorage.getItem(environment.TOKEN_KEY));
  return response;
};

export const addUser = async (requestBody) => {
  const response = await Post(`${_endpoint}/Create`, requestBody, true);
  return response;
};

export const updateUser = async (requestBody) => {
  const response = await Post(`${_endpoint}/Update`, requestBody, true);
  return response;
};

export const deleteUser = async (id) => {
  const deleteData = {
    "id": id
  }
  const response = await Post(`${_endpoint}/Delete`, deleteData, true);
  return response;
};
