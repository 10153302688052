import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Button,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
  Input,
} from "reactstrap";

import "./SiteSetting.css";

import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import { getSettings, UpdateVisitPeriod } from "../../services/setting.service";

import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import Loader from "../partials/Loader";
import { setGlobalLoading } from "../../store/slices/loadingSlice";
import { ClimbingBoxLoader } from "react-spinners";

const SiteSetting = (props) => {
  const [tab, setTab] = useState("3");
  const [morningPeriodFrom, setMorningPeriodFrom] = useState();
  const [morningPeriodTo, setMorningPeriodTo] = useState();
  const [eveningPeriodFrom, setEveningPeriodFrom] = useState();
  const [eveningPeriodTo, setEveningPeriodTo] = useState();
  const [installingFrom, setInstallingFrom] = useState();
  const [installingTo, setInstallingTo] = useState();

  const [smsSender, setSmsSender] = useState(false);
  const [emailSender, setEmailSender] = useState(false);
  const [defaultSenderName, setDefaultSenderName] = useState("");


  // TimePicker
  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [installingError, setInstallingError] = useState(false);
  const [morningPeriodError, setMorningPeriodError] = useState(false);
  const [eveningPeriodError, setEveningPeriodError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(true);


  const postTime = () => {
    dispatch(setGlobalLoading({ status: true }));
    const requestBody = {
      enableEmailSender: emailSender,
      enableSmsSender: smsSender,
      defaultSmsSenderName: defaultSenderName,
      // installingPeriod: {
      //   fromTime: installingFrom,
      //   toTime: installingTo,
      // },
      // measuringMorningPeriod: {
      //   fromTime: morningPeriodFrom,
      //   toTime: morningPeriodTo,
      // },
      // measuringEveningPeriod: {
      //   fromTime: eveningPeriodFrom,
      //   toTime: eveningPeriodTo,
      // },
    };
    (async () => {
      const { ok } = await UpdateVisitPeriod(requestBody);

      if (ok) {
        setTimeout(() => {
          toast.success(props.t("siteSettingsUpdatedSuccessfully"));
        }, 200);
        setRefreshData((prev) => !prev);
      }

      dispatch(setGlobalLoading({ status: false }));
    })();
  };

  const getTime = () => {
    (async () => {
      setLoading(true);
      let morningFromHours, morningToHours;
      let morningFromMinutes, morningToMinutes;
      let eveningFromHours, eveningToHours;
      let eveningFromMinutes, eveningToMinutes;
      let installingFromHours, installingToHours;
      let installingFromMinutes, installingToMinutes;
      const { body, ok } = await getSettings();
      if (ok) {
        console.log(body);
        setEmailSender(body.enableEmailSender);
        setSmsSender(body.enableSmsSender);
        setDefaultSenderName(body.defaultSmsSenderName);
        if (body.installingPeriodSetting.fromTime.hours > 9)
          installingFromHours = body.installingPeriodSetting.fromTime.hours;
        else
          installingFromHours = "0" + body.installingPeriodSetting.fromTime.hours;
        if (body.installingPeriodSetting.fromTime.minutes > 9)
          installingFromMinutes = body.installingPeriodSetting.fromTime.minutes;
        else
          installingFromMinutes = body.installingPeriodSetting.fromTime.minutes + "0";
        setInstallingFrom(installingFromHours + ":" + installingFromMinutes);
        if (body.installingPeriodSetting.toTime.hours > 9)
          installingToHours = body.installingPeriodSetting.toTime.hours;
        else
          installingToHours = "0" + body.installingPeriodSetting.toTime.hours;
        if (body.installingPeriodSetting.toTime.minutes > 9)
          installingToMinutes = body.installingPeriodSetting.toTime.minutes;
        else
          installingToMinutes = body.installingPeriodSetting.toTime.minutes + "0";
        setInstallingTo(installingToHours + ":" + installingToMinutes);
        if (body.periodSettings[0].fromTime.hours > 9)
          morningFromHours = body.periodSettings[0].fromTime.hours;
        else
          morningFromHours = "0" + body.periodSettings[0].fromTime.hours;
        if (body.periodSettings[0].fromTime.minutes > 9)
          morningFromMinutes = body.periodSettings[0].fromTime.minutes;
        else
          morningFromMinutes = body.periodSettings[0].fromTime.minutes + "0";
        setMorningPeriodFrom(morningFromHours + ":" + morningFromMinutes);
        if (body.periodSettings[0].toTime.hours > 9)
          morningToHours = body.periodSettings[0].toTime.hours;
        else
          morningToHours = "0" + body.periodSettings[0].toTime.hours;
        if (body.periodSettings[0].toTime.minutes > 9)
          morningToMinutes = body.periodSettings[0].toTime.minutes;
        else
          morningToMinutes = body.periodSettings[0].toTime.minutes + "0";
        setMorningPeriodTo(morningToHours + ":" + morningToMinutes);

        if (body.periodSettings[1].fromTime.hours > 9)
          eveningFromHours = body.periodSettings[1].fromTime.hours;
        else
          eveningFromHours = "0" + body.periodSettings[1].fromTime.hours;
        if (body.periodSettings[1].fromTime.minutes > 9)
          eveningFromMinutes = body.periodSettings[1].fromTime.minutes;
        else
          eveningFromMinutes = body.periodSettings[1].fromTime.minutes + "0";
        setEveningPeriodFrom(eveningFromHours + ":" + eveningFromMinutes);
        if (body.periodSettings[1].toTime.hours > 9)
          eveningToHours = body.periodSettings[1].toTime.hours;
        else
          eveningToHours = "0" + body.periodSettings[1].toTime.hours;
        if (body.periodSettings[1].toTime.minutes > 9)
          eveningToMinutes = body.periodSettings[1].toTime.minutes;
        else
          eveningToMinutes = body.periodSettings[1].toTime.minutes + "0";
        setEveningPeriodTo(eveningToHours + ":" + eveningToMinutes);
      }
      setLoading(false);
    })();
  };
  useEffect(() => {
    getTime();
  }, [refreshData]);

  const handleInstallationTo = (value) => {
    if (value < installingFrom) {
      setInstallingError(true);
      setIsSubmitting(false);
    }
    else {
      setInstallingError(false);
      setInstallingTo(value);
      setIsSubmitting(true);
    }

    // setInstallingError(false);
  }
  const handleMorningPeriod = (value) => {
    if (value < morningPeriodFrom) {
      setMorningPeriodError(true);
      setIsSubmitting(false);
    }
    else {
      setMorningPeriodError(false);
      setMorningPeriodTo(value);
      setIsSubmitting(true);
    }
  }
  const handleEveningPeriod = (value) => {
    if (value < eveningPeriodFrom) {
      setEveningPeriodError(true);
      setIsSubmitting(false);
    }
    else {
      setEveningPeriodError(false);
      setEveningPeriodTo(value);
      setIsSubmitting(true);
    }
  }
  return (
    <Fragment>
      <Breadcrumb parent={props.t("home")} title={props.t("siteSettings")} />
      <Container fluid={true} className="align-items-center">
        <Card>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Row>
                <Col sm="12" className="card-body">
                  <Nav
                    tabs
                    className="border-tab mb-0"
                  >
                    {/* <NavItem>
                      <NavLink
                        className={tab === "1" ? "active " : ""}
                        onClick={() => setTab("1")}
                        type="button"
                      >
                        {props.t("measuringPeriodSettings")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={tab === "2" ? "active " : ""}
                        onClick={() => setTab("2")}
                        type="button"
                      >
                        {props.t("installingPeriodSettings")}
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        className={tab === "3" ? "active " : ""}
                        onClick={() => setTab("3")}
                        type="button"
                      >
                        {props.t("SMS/EmailSender")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={tab}>
                    <TabPane className="fade show " tabId="1">
                      <Col sm="12" md="12">
                        {/* <CardTitle className='pt-4 pl-3'>
                                            <h4>{props.t("measuringPeriodSettings")}</h4>
                                        </CardTitle> */}
                        <CardBody>
                          <Row>
                            <Col sm="6" md="6">
                              <CardTitle>
                                <h6>{props.t("morningPeriod")}</h6>
                              </CardTitle>
                            </Col>
                            <Col sm="6" md="6">
                              <CardTitle>
                                <h6>{props.t("eveningPeriod")}</h6>
                              </CardTitle>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="6" md="6">
                              {/* Measurement Morning Period */}
                              <div className="form-row">
                                <FormGroup className="form-row col-md-12">
                                  <label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("from")}:
                                  </label>
                                  <div className="col-sm-9 col-md-6">
                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      value={morningPeriodFrom}
                                      onChange={(value) => setMorningPeriodFrom(value.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      sx={{ width: 200 }}
                                    />
                                  </div>
                                </FormGroup>
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("to")}:
                                  </Label>
                                  <div className="col-sm-9 col-md-6">
                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      value={morningPeriodTo}
                                      onChange={(value) => handleMorningPeriod(value.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      error={morningPeriodError}
                                      sx={{ width: 200 }}
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                            <Col sm="6" md="6">
                              {/* Measurement Evening Period */}
                              <div className="form-row">
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("from")}:
                                  </Label>
                                  <div className="col-sm-9 col-md-6">
                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      value={eveningPeriodFrom}
                                      onChange={(value) => setEveningPeriodFrom(value.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      sx={{ width: 200 }}
                                    />
                                  </div>
                                </FormGroup>
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("to")}:
                                  </Label>
                                  <div className="col-sm-9 col-md-6">
                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      value={eveningPeriodTo}
                                      onChange={(value) => handleEveningPeriod(value.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      error={eveningPeriodError}
                                      sx={{ width: 200 }}
                                    />
                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col sm="12" md="12">
                        <CardBody>
                          {/* istalation Period */}
                          <Row>
                            <Col md="6 mb-3">
                              <div className="form-row">
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("from")}:
                                  </Label>
                                  <div className="col-sm-9 col-md-6">
                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      defaultValue={installingFrom}
                                      onChange={(value) => setInstallingFrom(value.target.value)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      sx={{ width: 200 }}
                                    />
                                  </div>
                                </FormGroup>

                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-3 col-md-3 col-form-label text">
                                    {props.t("to")}:
                                  </Label>
                                  <div className="col-sm-9 col-md-6">

                                    <TextField
                                      id="time"
                                      label={
                                        <i className="fa fa-clock-o"></i>
                                      }
                                      type="time"
                                      value={installingTo}
                                      onChange={(value) => {
                                        handleInstallationTo(value.target.value);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                      error={installingError}
                                      sx={{ width: 200 }}
                                    />


                                  </div>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col sm="12" md="12">
                        <CardBody>
                          {/* istalation Period */}
                          <Row>
                            <Col md="12 mb-3">
                              <div className="form-row">
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-5 col-md-5 col-form-label text">
                                    {props.t("enableSmsSender")}:
                                  </Label>
                                  <input
                                    className="mt-2"
                                    type="checkbox"
                                    name="smsSender"
                                    checked={smsSender}
                                    onChange={(event) => {
                                      setSmsSender(event.target.checked);
                                    }}
                                  />

                                </FormGroup>

                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-5 col-md-5 col-form-label text">
                                    {props.t("enableEmailSender")}:
                                  </Label>
                                  <input
                                    className="mt-2"
                                    type="checkbox"
                                    name="emailSender"
                                    checked={emailSender}
                                    onChange={(event) => {
                                      setEmailSender(event.target.checked);
                                    }}
                                  />

                                </FormGroup>
                                <FormGroup className="form-row col-md-12">
                                  <Label className="col-sm-5 col-md-5 col-form-label text">
                                    {props.t("defaultSenderName")}:
                                  </Label>
                                  <Input
                                    className="col-sm-3 col-md-3"
                                    type="text"
                                    id={`name_${defaultSenderName}`}
                                    name="defaultSenderName"
                                    defaultValue={defaultSenderName}
                                    onChange={(event) => {
                                      setDefaultSenderName(event.target.value);
                                      console.log(event.target.value);
                                    }}

                                  ></Input>
                                  {/* <Input

                                    type="checkbox"
                                    name="emailSender"
                                    checked={emailSender}
                                  
                                  /> */}

                                </FormGroup>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <CardFooter className="submit-footer">
                    <Button
                      color="primary"
                      onClick={postTime}
                      disabled={!isSubmitting}>
                      {props.t("save")}
                    </Button>
                  </CardFooter>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Container>
    </Fragment>
  );
};
export default translate(SiteSetting);
