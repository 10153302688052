import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  assignTechVisit,
  getAssignOrderData,
} from "../../services/order.service.js";

import { Typeahead } from "react-bootstrap-typeahead";

import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import environment from "../../environment.js";
import { setGlobalLoading } from "../../store/slices/loadingSlice.js";

// End TimePicker
const AssignOrder = ({
  isOpen,
  toggleModal = () => { },
  t,
  setSubmitting = () => { },
  toggleRefreshTable = () => { },
}) => {
  const [techniciansOption, setTechniciansOption] = useState([]);
  const [visitPeriod, setVisitPeriod] = useState("");
  const [timeRange, setTimeRange] = useState({});
  const [fromTime, setFromTime] = useState({});
  const [toTime, setToTime] = useState({});


  const languageKey = useSelector((state) => state.language.key);

  // Submit Values
  const [orderId, setOrderId] = useState("");
  const [visitTime, setVisitTime] = useState();
  const [visitDate, setVisitDate] = useState(new Date());
  const [technicianId, setTechnicianId] = useState("");

  // Order Data
  let orderData;
  if (environment.ORDER_KEY in localStorage) {
    orderData = JSON.parse(localStorage.getItem(environment.ORDER_KEY));
    localStorage.removeItem(environment.ORDER_KEY);
    setOrderId(orderData.id);
    setVisitPeriod(orderData.visitPeriod);
  }

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const timeLocaleMap = {
    ar: "ar-SA",
    en: "en-US",
  };
  const timeDisplayOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handleTechnicianSelect = (value) => {
    if (value.length) {
      setTechnicianId(value[0].id);
    } else {
      setTechnicianId("");
    }
  };
  const assignTechnical = (e) => {
    e.preventDefault();
    toggleModal();
    dispatch(setGlobalLoading({ status: true }));

    const requestBody = {
      id: orderId,
      measureTechnicianId: technicianId,
      visitDate: visitDate.toLocaleDateString("en-GB"),
      visitTime: visitTime,
    };

    (async () => {
      const { ok } = await assignTechVisit(requestBody);
      if (ok) {
        toast.success(t("technicalAssignedSuccessfuly"));
      }

      setVisitDate(new Date());
      // setVisitTime("");
      setTechnicianId("");
      toggleRefreshTable();
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  useEffect(() => {
    if (orderId !== "" && orderId) {
      setLoading(true);
      let fromHours, fromMinutes;
      let toHours, toMinutes;
      (async () => {
        const { body, ok } = await getAssignOrderData(orderId);
        if (ok) {
          setTechniciansOption(body.technichians);
          if (body.visitPeriods.fromTime.hours > 9)
            fromHours = body.visitPeriods.fromTime.hours;
          else
            fromHours = "0" + body.visitPeriods.fromTime.hours;
          if (body.visitPeriods.fromTime.minutes > 9)
            fromMinutes = body.visitPeriods.fromTime.minutes;
          else
            fromMinutes = body.visitPeriods.fromTime.minutes + "0";
          setFromTime(fromHours + ":" + fromMinutes);
          if (body.visitPeriods.toTime.hours > 9)
            toHours = body.visitPeriods.toTime.hours;
          else
            toHours = "0" + body.visitPeriods.toTime.hours;
          if (body.visitPeriods.toTime.minutes > 9)
            toMinutes = body.visitPeriods.toTime.minutes;
          else
            toMinutes = body.visitPeriods.toTime.minutes + "0";
          setToTime(toHours + ":" + toMinutes);
          setTimeRange({
            from: new Date(
              2022,
              1,
              1,
              body.visitPeriods.fromTime.hours,
              body.visitPeriods.fromTime.minutes
            ),
            to: new Date(
              2022,
              1,
              1,
              body.visitPeriods.toTime.hours,
              body.visitPeriods.toTime.minutes
            ),
          });
        }
      }
      )();
      setLoading(false);
    }
  }, [orderId]);
  const handleTime = (value) => {
    setVisitTime(value);
    setIsSubmitting(true);
    // if (value >= fromTime && value <= toTime) {
    //   setTimeError(false);
    // }
    // else {
    //   setTimeError(true);
    //   setIsSubmitting(false);
    // }
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal} centered>
        <ModalHeader>{t("assignTechnical")}</ModalHeader>
        <Form className="form theme-form" onSubmit={assignTechnical}>
          <ModalBody>
            <FormGroup className="row d-flex align-items-center">
              <Label className="col-4 mb-0">{t("technical")}</Label>
              <Typeahead
                className="col-7 p-0"
                id="select-technician"
                options={techniciansOption}
                labelKey="displayName"
                onChange={handleTechnicianSelect}
                placeholder={t("selectPlaceholder")}
                isLoading={loading}
                clearButton
                key={"id"}
              ></Typeahead>
            </FormGroup>
            {/* <FormGroup className="row d-flex align-items-center">
              <Label className="col-4 mb-0">{t("visitPeriod")}</Label>
              <Input
                type="text"
                name="visitPeriod"
                className="col-7 mb-0"
                disabled
                defaultValue={visitPeriod}
              ></Input>
            </FormGroup> */}
            <FormGroup className="row">
              <label className="col-4 mb-0">{t("date")}</label>
              <div className="col-7 mb-0 p-0">
                <DatePicker
                  name="date"
                  className="form-control digits"
                  dateFormat="dd/MM/yyyy"
                  selected={visitDate}
                  onChange={setVisitDate}
                  minDate={new Date()}
                />
              </div>
            </FormGroup>
            <FormGroup className="row">
              <label className="col-4 mb-0">{t("time")}</label>
              <div className="col-7 p-0">
                <TextField
                  id="time"
                  label={
                    <i className="fa fa-clock-o"></i>
                  }
                  type="time"
                  value={visitTime}
                  onChange={(value) => handleTime(value.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    inputProps: {
                      step: 300, // 5 min
                    }
                  }}
                  error={timeError}
                  sx={{ width: 290 }}
                />
                {/* {!loading && (
                  <p
                    style={{
                      color: "var(--theme-default)",
                      fontSize: languageKey === "en" ? "0.7rem" : "0.9rem",
                    }}
                  >{`${t("selectTimeNote", {
                    from: timeRange.from?.toLocaleTimeString(
                      timeLocaleMap[languageKey],
                      timeDisplayOptions
                    ),
                    to: timeRange.to?.toLocaleTimeString(
                      timeLocaleMap[languageKey],
                      timeDisplayOptions
                    ),
                  })}`}</p>
                )} */}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              color="primary"
              type="submit"
              disabled={technicianId === "" || !isSubmitting}
            >
              {t("save")}
            </Button>
            <Button
              color="light"
              onClick={toggleModal}
              type="button">
              {t("cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};
export default withRouter(translate(AssignOrder));
