import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardFooter,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Popover,
  PopoverBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { KPSearchSelect } from "../../KPComponents/partials/KPSearchSelect";
import Breadcrumb from "../../layout/breadcrumb";
import DatePicker from "react-datepicker";
import { getAllCities } from "../../services/city.service";
import { getCityNeighborhoods } from "../../services/neighborhood.service";
import { getAllClients } from "../../services/client.service";
import { getAllBranches } from "../../services/branch.service";
import {
  addContract,
  isContractNumberExistOptional,
} from "../../services/order.service";

import CreateClient from "../client/CreateClient";

import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../store/slices/loadingSlice";

const AddContrcatDE = ({ t, history }) => {

  const [contractDate, setContractDate] = useState(new Date());

  const [clientOption, setClientOption] = useState({});
  const [branchOptions, setBranchOptions] = useState({});
  const [cityOptions, setCityOptions] = useState({});
  const [neighborhoodOptions, setNeighborhoodOptions] = useState({});
  // IMAGES AND FILES
  const [contractImage, setContractImage] = useState({});
  // Form validation
  const [validContractNumber, setValidContractNumber] = useState(true);
  const [contractNumber, setContractNumber] = useState("");
  // Create Order
  const [clientId, setClientId] = useState({});
  const [cityId, setCityId] = useState({});
  const [branchId, setBranchId] = useState({});
  const [neighborhoodId, setNeighborhoodId] = useState({});
  // Create Client Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [refreshClientOptions, setRefreshClientOptions] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleRefreshClientOptions = () => {
    setRefreshClientOptions(!refreshClientOptions);
  };
  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [branchLoading, setBranchLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [neighborhoodLoading, setNeighborhoodLoading] = useState(false);
  const [contractNumberCheckLoading, setContractNumberCheckLoading] =
    useState(false);
  const isSubmitDisabled =
    neighborhoodId.value &&
    clientId.value &&
    cityId.value &&
    branchId.value &&
    contractImage.length > 0 &&
    contractDate &&
    contractNumber;

  // FORM
  const ACCEPT_TYPES = "image/*, application/pdf";

  const styles = {
    container: () => ({
      width: "100%",
      marginInlineEnd: 8,
    }),
  };
  let bodyFormData;
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setGlobalLoading({ status: true }));
    bodyFormData = new FormData();
    bodyFormData.append("clientId", clientId.value);
    bodyFormData.append("cityId", cityId.value);
    bodyFormData.append("neighborhoodId", neighborhoodId.value);
    bodyFormData.append("branchId", branchId.value);
    bodyFormData.append(
      "contractNumber",
      branchId.code + "-" + event.target.contractNumber.value
    );
    bodyFormData.append("contractDate", event.target.date.value);
    bodyFormData.append("contractImage", contractImage[0]);

    (async () => {
      const { ok } = await addContract(bodyFormData);
      if (ok) {
        toast.success(t("orderAddedSuccessfuly"));
        history.push(`${process.env.PUBLIC_URL}/orders`);
      }
      // else console.log(body);
      dispatch(setGlobalLoading({ status: false }));
    })();
  };
  const checkContractNumber = (value) => {
    setContractNumberCheckLoading(true);
    (async () => {
      const { body, ok } = await isContractNumberExistOptional(value);
      if (ok) {
        setValidContractNumber(!body);
      }

      setContractNumberCheckLoading(false);
    })();
  };
  const handleCancel = () => {
    history.push(`${process.env.PUBLIC_URL}/orders`);
  };

  useEffect(() => {
    setClientLoading(true);
    (async () => {
      const { body, ok } = await getAllClients();
      if (ok) {
        const options = body.map((client) => ({
          value: client.id,
          label: `${client.firstName} ${client.sureName} / ${client.phoneNumber}`,
        }));
        setClientOption(options);
      }

      setClientLoading(false);
    })();
  }, [refreshClientOptions]);
  useEffect(() => {
    setCityLoading(true);
    setNeighborhoodLoading(true);
    (async () => {
      const { body, ok } = await getAllCities();
      if (ok) {
        const options = body.map((city) => ({
          value: city.id,
          label: city.localizedName,
        }));
        setCityOptions(options);
      }

      setCityLoading(false);
      setNeighborhoodLoading(false);
    })();
  }, []);
  useEffect(() => {
    setBranchLoading(true);
    (async () => {
      const { body, ok } = await getAllBranches();
      if (ok) {
        const options = body.map((branch) => ({
          value: branch.id,
          label: branch.name,
          code: branch.code,
        }));
        setBranchOptions(options);
      }
      setBranchLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(cityId).length > 0) {
      setNeighborhoodLoading(true);
      (async () => {
        const { body, ok } = await getCityNeighborhoods(cityId.value);

        if (ok) {
          const options = body.map((neighborhood) => ({
            value: neighborhood.id,
            label: neighborhood.localizedName,
          }));
          setNeighborhoodOptions(options);
        }
        setNeighborhoodLoading(false);
      })();
    }
  }, [cityId]);
  return (
    <Fragment>
      <CreateClient
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        setSubmitting={setSubmitting}
        toggleRefreshTable={toggleRefreshClientOptions}
      />

      <Breadcrumb parent={t("customer")} title={t("addContract")} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={handleSubmit}>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup className="row">
                        <Label className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label">
                          {t("clientName")}
                        </Label>
                        <Col sm="9 d-flex">
                          <KPSearchSelect
                            options={clientOption}
                            setValue={setClientId}
                            style={styles}
                            loading={clientLoading}
                          />

                          <Button
                            color="primary"
                            onClick={toggleModal}
                            disabled={submitting}
                          >
                            +
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label">
                          {t("branch")}
                        </Label>
                        <Col sm="9">
                          <div className="col-12 col-md-12 col-xl-12 p-0 mb-0">
                            <KPSearchSelect
                              options={branchOptions}
                              setValue={(value) => {
                                setBranchId(value);
                              }}
                              loading={branchLoading}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label">
                          {t("city")}
                        </Label>
                        <Col sm="9">
                          <div className="col-12 col-md-12 col-xl-12 p-0 mb-0">
                            <KPSearchSelect
                              options={cityOptions}
                              setValue={setCityId}
                              loading={cityLoading}
                            />
                          </div>
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label">
                          {t("neighborhood")}
                        </Label>
                        <Col sm="9">
                          <div className="col-12 col-md-12 col-xl-12 p-0 mb-0">
                            <KPSearchSelect
                              options={neighborhoodOptions}
                              setValue={setNeighborhoodId}
                              loading={neighborhoodLoading}
                              value={neighborhoodId}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          htmlFor="contractNumber"
                          className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label"
                        >
                          {t("contractNumber")}
                        </Label>
                        <Col sm="9">
                          <div className="col-12 col-md-12 col-xl-12 p-0 mb-0">
                            <InputGroup>
                              <Input
                                className={`form-control ${!validContractNumber &&
                                  contractNumber !== "" &&
                                  !contractNumberCheckLoading
                                  ? "is-invalid"
                                  : ""
                                  } ${validContractNumber &&
                                    contractNumber !== "" &&
                                    !contractNumberCheckLoading
                                    ? "is-valid"
                                    : ""
                                  }`}
                                id="contractNumber"
                                name="contractNumber"
                                type="text"
                                value={contractNumber ?? ""}
                                onChange={(event) => {
                                  setContractNumberCheckLoading(true);
                                  setContractNumber(event.target.value);
                                }}
                                onBlur={(event) =>
                                  checkContractNumber(
                                    `MA-${event.target.value}`
                                  )
                                }
                                style={{ direction: "ltr" }}
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ direction: "ltr" }}>
                                  { 
                                    <div>MA-</div>
                                  }
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </div>
                        </Col>
                        <Popover
                          className="bg-danger"
                          target="contractNumber"
                          isOpen={
                            !validContractNumber &&
                            contractNumber !== "" &&
                            !contractNumberCheckLoading
                          }
                          placement="top"
                        >
                          <PopoverBody className="bg-danger">
                            {t("contractNumberExist")}
                          </PopoverBody>
                        </Popover>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label">
                          {t("date")}
                        </Label>
                        <Col sm="9">
                          <div className="col-12 col-md-12 col-xl-12 p-0 mb-0">
                            <DatePicker
                              name="date"
                              className="form-control"
                              selected={contractDate}
                              onChange={setContractDate}
                              maxDate={new Date()}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="col-6 col-sm-3 col-md-3 col-xl-3 col-form-label"
                          //  label-required"
                          htmlFor="contractImage"
                        >
                          {t("contractImage")}
                        </Label>
                        <Col sm="9">
                          <div
                            className="col-12 col-md-12 col-xl-12 p-0 mb-0"
                            style={{ zIndex: "0" }}
                          >
                            <CustomInput
                              className="form-control"
                              id="contractImage"
                              name="contractImage"
                              label={`${t("pleaseSelectFile")}`}
                              type="file"
                              accept={ACCEPT_TYPES}
                              value={""}
                              // onChange={handleContractFiles}
                              onChange={(event) =>
                                setContractImage(Array.from(event.target.files))
                              }
                            ></CustomInput>
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="submit-footer">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={
                      !isSubmitDisabled ||
                      contractNumberCheckLoading ||
                      !validContractNumber
                    }
                  >
                    {t("save")}
                  </Button>
                  <Button color="light" type="reset" onClick={handleCancel}>
                    {t("cancel")}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default withRouter(translate(AddContrcatDE));
