import React, { useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  CardFooter,
  Input,
  Label,
  Collapse,
  FormGroup,
  Form,
  CardHeader,
} from "reactstrap";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import AssignOrder from "./AssignOrder";
import EditOrder from "./EditOrder";
import { useDispatch, useSelector } from "react-redux";

import environment from "../../environment";
import { setEditOrderId } from "../../store/slices/orderSlice";
import { getAllBranches } from "../../services/branch.service";
import { getStatus } from "../../services/order.service";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { RefreshCw } from "react-feather";
import { KPTable } from "../partials/KPTable";
import TimePeriodLocalization from "../partials/TimePeriodLocalization/TimePeriodLocalization";
import { getAllUsers, search } from "../../services/users.srvices";
const ViewOrders = ({ t, history }) => {
  // STATES Declaration
  const [branchesData, setBranchesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [orderStatusData, setOrderStatusData] = useState([]);
  const [branchesIds, setBranchesIds] = useState([]);
  // new filters to submit
  const [userCreatedById, setUserCreatedById] = useState([]);
  const [measureTechnicianId, setMeasureTechnicianId] = useState([]);
  const [installingTechnicianId, setInstallingTechnicianId] = useState([]);
  const [status, setStatus] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contractNumber, setContractNumber] = useState('');
  const [orderCode, setOrderCode] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [edit, setEdit] = useState(false);
  const [details, setDetails] = useState(false);
  const [assign, setAssign] = useState(false);

  const [requestOptions, setRequestOptions] = useState({
    orderCode: "",
    contractNumber: "",
    branchIds: [],
    clientPhoneNumber: "",
    orderStatus: [],
    fromDate: "",
    toDate: "",
    orderBy: "string",
    searchText: "",
  });

  // Store
  const dispatch = useDispatch();

  // UI/UX
  const [refreshTable, setRefreshTable] = useState(false);
  const langaugeKey = useSelector((state) => state.language.key);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  // loadings
  const [branchesLoading, setBranchesLoading] = useState(false);
  const [orderStatusLoading, setOrderStatusLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);

  const toggleAssignModal = () => {
    setIsAssignModalOpen(!isAssignModalOpen);
  };
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const toggleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };

  const handleStatus = (row) => {
    if (row.orderStatus === "Rejected") {
      return (
        <p
          className="text-danger font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.orderStatusLocalized || t("notEntered")}
        </p>
      );
    } else if (
      row.orderStatus === "UpdatedRequest" ||
      row.orderStatus === "InstallingProcessing"
    ) {
      return (
        <p
          className=" text-warning font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.orderStatusLocalized || t("notEntered")}
        </p>
      );
    } else {
      return (
        <p
          className="text-success font-weight-bold"
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          {row.orderStatusLocalized || t("notEntered")}
          {row?.orderStatus === "AssignedToTechnician" && row?.measurmentUpdateRequested &&
            <>
              &nbsp;({t("reAssigned")})
            </>
          }
        </p>
      );
    }
  };

  const handleBtn = () => {
    setEdit(!handleClaims("Orders.EditOrderVisitDateTime"));
    setDetails(!handleClaims("Orders.ViewDetails"));
    setAssign(!handleClaims("Orders.AssignToMeasuringTech"));
  };

  // Data Table data configuration
  const tableColumns = [
    {
      name: t("orderCode"),
      selector: "orderCode",
      center: true,
      minWidth: "130px",
    },
    {
      name: t("status"),
      selector: "orderStatus",
      cell: (row) => handleStatus(row),
      center: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: t("orderDate"),
      selector: (row) =>
        row.orderDate.substring(0, row.orderDate.length - 3) +
        " " +
        TimePeriodLocalization(
          row.orderDate.substring(
            row.orderDate.length - 2,
            row.orderDate.length
          )
        ),
      center: true,
      minWidth: "190px",
    },
    {
      name: t("clientName"),
      selector: (row) => row.client.firstName + " " + row.client.sureName,
      center: true,
      minWidth: "150px",
    },
    {
      name: t("clientPhone"),
      selector: "client.phoneNumber",
      center: true,
      minWidth: "150px",
    },
    {
      name: t("branchName"),
      selector: "branchName",
      center: true,
    },
    {
      cell: (row) =>
        handleClaims("Orders.ViewDetails") ? (
          <Button
            color="primary"
            size="sm"
            className="mr-1"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/orders/tabs/${row.id}`);
            }}
          >
            {t("details")}
          </Button>
        ) : (
          <div></div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: details,
      minWidth: "120px",
    },
    {
      cell: (row) =>
        handleClaims("Orders.AssignToMeasuringTech") ? (
          row.actions[0].isActive ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                localStorage.setItem(
                  environment.ORDER_KEY,
                  JSON.stringify(row)
                );
                toggleAssignModal();
              }}
            >
              {t("assign")}
            </Button>
          ) : (
            <Button color="outline-primary" size="sm" disabled>
              {t("assign")}
            </Button>
          )
        ) : (
          <div></div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: assign,
      minWidth: "110px",
    },
    {
      cell: (row) =>
        handleClaims("Orders.EditOrderVisitDateTime") ? (
          row.actions.filter(
            (action) => action.actionId === "UpdateOrderVisitDate"
          )[0].isActive ? (
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                dispatch(setEditOrderId({ id: row.id }));
                toggleEditModal();
              }}
            >
              {t("edit")}
            </Button>
          ) : (
            <Button color="outline-primary" size="sm" disabled>
              {t("edit")}
            </Button>
          )
        ) : (
          <div></div>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: edit,
      minWidth: "110px",
    },
  ];

  // CONNECT WITH API
  const getBranches = () => {
    (async () => {
      setBranchesLoading(true);
      const { body, ok } = await getAllBranches();
      if (ok) {
        setBranchesData(body);
      }

      setBranchesLoading(false);
    })();
  };
  const getOrderStatus = () => {
    (async () => {
      setOrderStatusLoading(true);
      const { body, ok } = await getStatus();
      if (ok) {
        setOrderStatusData(body);
      }

      setOrderStatusLoading(false);
    })();
  };
  const getUsersData = () => {
    (async () => {
      setUsersLoading(true);
      const requestBody = {
        pageIndex: 0,
        pageSize: 100000
      }
      const { body, ok } = await search(requestBody);
      let users = []
      // console.log(body)
      if (ok) {
        body.items.forEach((item) => {
          users.push({ id: item.id, value: item.displayName })
        })
        setUsersData(users);
        // console.log(users)
      }

      setUsersLoading(false);
    })();
  };

  const getFilteredData = (value) => {
    let branchesIdsToSubmit = [];
    let orderStatusToSubmit = [];
    if (value === "reset") {
      setRequestOptions({
        orderCode: "",
        branchIds: [],
        clientPhoneNumber: "",
        contractNumber: "",
        orderStatus: [],
        fromDate: "",
        toDate: "",
        createdByUserId: "",
        measureTechnicianId: "",
        installingTechnicianId: "",
        controledPageIndex: true,
        orderBy: "string",
        searchText: "string",
      });
    } else {
      branchesIdsToSubmit = [];
      if (branchesIds.length > 0) {
        branchesIdsToSubmit = branchesIds.map((branch) => branch.id);
      }
      orderStatusToSubmit = [];
      if (status.length > 0) {
        orderStatusToSubmit = status.map((item) => item.id);
      }
      setRequestOptions({
        orderCode: orderCode || "",
        branchIds: branchesIdsToSubmit,
        clientPhoneNumber: phoneNumber || "",
        contractNumber: contractNumber || "",
        orderStatus: orderStatusToSubmit,
        fromDate: fromDate ? fromDate.toLocaleDateString("en-GB") : "",
        toDate: toDate ? toDate.toLocaleDateString("en-GB") : "",
        createdByUserId: userCreatedById[0]?.id,
        measureTechnicianId: measureTechnicianId[0]?.id,
        installingTechnicianId: installingTechnicianId[0]?.id,
        orderBy: "string",
        searchText: "string",
      });
    }
    // console.log(requestOptions);
    toggleRefreshTable();
  };
  const resetData = () => {
    setOrderCode("");
    setBranchesIds([]);
    setPhoneNumber("");
    setStatus([]);
    setFromDate("");
    setToDate("");
    setUserCreatedById([])
    setMeasureTechnicianId([])
    setInstallingTechnicianId([])
    setIsFilter(false);
    getFilteredData("reset");
  };

  useEffect(() => {
    getBranches();
    getOrderStatus();
    getUsersData();
    handleBtn();
    //eslint-disable-next-line
  }, [refreshTable, langaugeKey]);
  const claimsData = JSON.parse(localStorage.getItem("newClaims"));

  const handleClaims = (claim) => {
    if (claimsData.claims.indexOf(claim) > -1) return true;
    else return false;
  };
  const handleInputBranchesChange = (input) => {
    setBranchesIds(input);
  };
  const handleInputStatusChange = (input) => {
    setStatus(input);
  };
  const handleUsersChange = (input) => {
    setUserCreatedById(input)
  };
  const handleInstallingTechnicianChange = (input) => {
    setInstallingTechnicianId(input)
  };
  const handleMeasureTechnicianChange = (input) => {
    setMeasureTechnicianId(input)
  };

  return (
    <>
      <AssignOrder
        isOpen={isAssignModalOpen}
        toggleModal={toggleAssignModal}
        toggleRefreshTable={toggleRefreshTable}
      />

      <EditOrder
        isOpen={isEditModalOpen}
        toggleModal={toggleEditModal}
        toggleRefreshTable={toggleRefreshTable}
      />

      <Breadcrumb parent={t("orders")} title={t("viewOrders")} />
      <Container fluid={true}>
        {/*  Filters Area   */}
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form" onSubmit={getFilteredData}>
                <CardHeader className="p-3">
                  <div className="mb-0 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2
                        color="pl-0"
                        data-toggle="collapse"
                        data-target="#collapseicon"
                        className="mb-0"
                        aria-expanded={isFilter}
                        aria-controls="collapseicon"
                      >
                        <h5>{t("search")}</h5>
                      </h2>
                      <FormGroup
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            getFilteredData();
                          }
                        }}
                        className=" mb-0  ml-3 mr-3 col-sm-12"
                      >
                        <Input
                          type="number"
                          name="phoneNumber"
                          placeholder={t("phoneNumber")}
                          max="10"
                          value={phoneNumber}
                          onChange={(event) => {
                            setPhoneNumber(
                              event.target.value.replace(/\s+/g, "")
                            );
                          }}
                        />
                      </FormGroup>
                    </div>
                    <h2>
                      <i
                        className="middle fa fa-angle-down float-right p-2"
                        onClick={() => setIsFilter(!isFilter)}
                        role="button"
                      ></i>
                    </h2>
                  </div>
                </CardHeader>

                <Collapse isOpen={isFilter}>
                  <CardBody className="filter-cards-view animate-chk">
                    <Row>
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("branch")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              id="selectBranches"
                              labelKey="name"
                              multiple
                              options={branchesData}
                              selected={branchesIds}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleInputBranchesChange}
                              isLoading={branchesLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>
                        {/* nre ddl */}
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("measureTechnician")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              // className="col-8 mb-0"
                              id="selectedMeasureTechnicianId"
                              labelKey="value"
                              options={usersData}
                              // selected={status}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleMeasureTechnicianChange}
                              isLoading={usersLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>
                        {/* new ddl */}
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("userCreatedBy")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              // className="col-8 mb-0"
                              id="selectedUSer"
                              labelKey="value"
                              options={usersData}
                              // selected={status}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleUsersChange}
                              isLoading={usersLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("from")}
                          </Label>
                          <Col sm="8">
                            <DatePicker
                              name="date"
                              className="form-control"
                              selected={fromDate}
                              onChange={setFromDate}
                            // minDate={new Date()}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("contractNumber")}
                          </Label>
                          <Col sm="8">
                            <Input
                              name="contractNumber"
                              value={contractNumber}
                              onChange={(event) => {
                                setContractNumber(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>


                      </Col>
                      {/* Second Column */}
                      <Col sm="6">
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("status")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              // className="col-8 mb-0"
                              id="selectstatus"
                              labelKey="name"
                              multiple
                              options={orderStatusData}
                              selected={status}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleInputStatusChange}
                              isLoading={orderStatusLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("installingTechnician")}
                          </Label>
                          <Col sm="8">
                            <Typeahead
                              // className="col-8 mb-0"
                              id="selectedUSer"
                              labelKey="value"
                              options={usersData}
                              // selected={status}
                              placeholder={t("selectPlaceholder")}
                              onChange={handleInstallingTechnicianChange}
                              isLoading={usersLoading}
                              clearButton
                              key={"id"}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("orderCode")}
                          </Label>
                          <Col sm="8">
                            <Input
                              type="number"
                              name="orderCode"
                              max="10"
                              value={orderCode}
                              onChange={(event) => {
                                setOrderCode(event.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-4 col-form-label">
                            {t("to")}
                          </Label>
                          <Col sm="8">
                            <DatePicker
                              name="date"
                              className="form-control"
                              selected={toDate}
                              onChange={setToDate}
                            />
                          </Col>
                        </FormGroup>

                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="justify-content-end">
                    <Button
                      color="primary"
                      className="mr-1"
                      onClick={getFilteredData}
                    >
                      {t("search")}
                    </Button>
                    <Button
                      color="light"
                      className="text-center"
                      onClick={resetData}
                    >
                      {t("reset")}
                    </Button>
                  </CardFooter>
                </Collapse>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle className="d-flex justify-content-end h-50">
                  {handleClaims("DataEntry.InsertContract") ? (
                    <Button
                      outline
                      color="primary"
                      className="mr-1"
                      onClick={() => {
                        history.push(
                          `${process.env.PUBLIC_URL}/orders/addContract`
                        );
                      }}
                      size="sm"
                    >
                      {t("addContractBtn")}
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  {handleClaims("Orders.Create") ? (
                    <Button
                      outline
                      color="primary"
                      className="mr-1"
                      onClick={() => {
                        history.push(`${process.env.PUBLIC_URL}/orders/add`);
                      }}
                      size="sm"
                    >
                      {t("addOrderbtn")}
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  <RefreshCw
                    className="m-2"
                    color="#4433FF"
                    onClick={resetData}
                    role="button"
                  />
                </CardTitle>
                <KPTable
                  endpoint={`${environment.ORDER_ENDPOINT}/Search`}
                  columns={tableColumns}
                  refreshData={refreshTable}
                  pageSize={30}
                  requestOptions={requestOptions}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(translate(ViewOrders));
