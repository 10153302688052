import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileName: localStorage.getItem("profileName") || "...",
    role: localStorage.getItem("role") || "...",
    branchesIds: localStorage.getItem("branchesIds") || [],
    defaultBranchId: localStorage.getItem("defaultBranchId") || "...",
  },
  reducers: {
    setProfileName: (state, action) => {
      state.profileName = action.payload.profileName;
      state.role = action.payload.role;
      state.branchesIds = action.payload.branchesIds;
      state.defaultBranchId = action.payload.defaultBranchId;
      localStorage.setItem("profileName", state.profileName);
      localStorage.setItem("role", state.role);
      localStorage.setItem("branchesIds", state.branchesIds);
      localStorage.setItem("defaultBranchId", state.defaultBranchId);
    },
  },
});

export const { setProfileName } = profileSlice.actions;

export default profileSlice.reducer;
