import React from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from "react-switch-lang";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    Form,
} from "reactstrap";
import ImagesView from '../ImagesView';


const Attachments = (props) => {
    return (
        <div>
            {
                props.contractType === "General" &&
                <Container fluid={true}>
                    <Row >
                        <Col sm="12" >
                            <Card>
                                <Form className="form theme-form">
                                    <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                        <h4> {props.t("kitchenDesign")}</h4>
                                    </CardHeader>
                                    <ImagesView images={props.kitchenManualDesign} />
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
            {
                props.contractType === "General" &&
                <Container fluid={true}>
                    <Row >
                        <Col sm="12" >
                            <Card>
                                <Form className="form theme-form">
                                    <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                        <h4>  {props.t("3DPhotos")}</h4>
                                    </CardHeader>
                                    <ImagesView images={props.kitchen3DDesign} />
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
            <Container fluid={true}>
                <Row >
                    <Col sm="12" >
                        <Card>
                            <Form className="form theme-form">
                                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                    <h4>{props.t("contractCopies")}</h4>
                                </CardHeader>
                                <ImagesView images={props.contractImageFile} />
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true}>
                <Row >
                    <Col sm="12" >
                        <Card>
                            <Form className="form theme-form">
                                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                    <h4>{props.t("kitchenSinkImages")}</h4>
                                </CardHeader>

                                <ImagesView images={props.washtubImagesFiles} />
                            </Form>
                        </Card>

                    </Col>
                </Row>
            </Container>
            {
                props.contractType === "General" &&
                <Container fluid={true}>
                    <Row >
                        <Col sm="12" >
                            <Card>
                                <Form className="form theme-form">
                                    <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                        <h4>{props.t("doorsHandlesImages")}</h4>
                                    </CardHeader>
                                    <ImagesView images={props.doorsHandleImagesFiles} />
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
            {
                props.contractType === "Marble" &&
                <Container fluid={true}>
                    <Row >
                        <Col sm="12" >
                            <Card>
                                <Form className="form theme-form">
                                    <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                                        <h4>{props.t("marbleImages")}</h4>
                                    </CardHeader>
                                    <ImagesView images={props.marbleImagesFiles} />
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    )
}
export default withRouter(translate(Attachments));