import React from "react";
import { translate } from "react-switch-lang";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Button,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { cancelAssignOrder } from "../../../services/order.service";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../store/slices/loadingSlice";

const CancelAssign = ({
    isOpen,
    toggleModal = () => { },
    orderId,
    t,
    history,
}) => {
    // Store
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        toggleModal();
        dispatch(setGlobalLoading({ status: true }));

        (async () => {
            const { ok } = await cancelAssignOrder(orderId);
            if (ok) {
                setTimeout(() => {
                    toast.success(t("cancelAssignDone"));
                }, 200);
                history.push(`${process.env.PUBLIC_URL}/orders`);
            }

            dispatch(setGlobalLoading({ status: false }));
        })();
    };
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggleModal} centered>
                <ModalHeader>{t("cancelAssign")}</ModalHeader>
                <Form className="form theme-form" onSubmit={handleSubmit}>
                    <ModalBody>
                        <p>{t("cancelAssignMsg")}</p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="danger" type="submit">
                            {t("confirm")}
                        </Button>
                        <Button color="light" onClick={toggleModal} type="button">
                            {t("cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>


    )
}

export default withRouter(translate(CancelAssign));
