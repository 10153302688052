import { Box } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import { translate } from "react-switch-lang";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  CardHeader,
} from "reactstrap";
import ImagesView from "./ImagesView";

const Sizes = (props) => {
  const data = props.data;
  return (
    <div>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("actualHight")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.actualKitchenHeight || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("notes")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.visitNotes || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("wallA")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.wallASize || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("wallB")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.wallBSize || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("wallC")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.wallCSize || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("wallD")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.wallDSize || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup className="row mb-0">
                        <Label className="col-sm-3 col-form-label">
                          {props.t("washTubCenter")}
                        </Label>
                        <Col sm="9">
                          <Label className="col-form-label font-weight-bold">
                            {data.washtubCenter || props.t("notEntered")}
                          </Label>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                  <h4> {props.t("sizesImages")}</h4>
                </CardHeader>
                <ImagesView images={props.measurmentImages} />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                  <h4> {props.t("kitchrenPicturesBefore")}</h4>
                </CardHeader>
                <ImagesView images={props.kitchenBeforeImages} />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <Form className="form theme-form">
                <CardHeader className="d-flex justify-content-start p-2 pt-3 pr-5 pl-5">
                  <h4> {props.t("projectVideoBeforeImplementation")}</h4>
                </CardHeader>
                <CardBody>
                  {data?.kitchenBeforeVideos?.length > 0
                    ? data?.kitchenBeforeVideos.map((video, index) => (
                        <Box sx={{ fontSize: 20 }} mb={2} key={index}>
                          <a
                            target="_blank"
                            href={`${video}` }
                            download
                          >
                            {props.t("video")} {index + 1} :{" "}
                            {props.t("clickToDownload")}
                          </a>
                        </Box>
                      ))
                    : props.t("notEntered")}
                </CardBody>{" "}
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(translate(Sizes));
